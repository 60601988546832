.btn-3::before,
.btn-3::after,
.btn.hover-border-1::before,
.btn.hover-border-1::after,
.btn.hover-border-1 span::before,
.btn.hover-border-1 span::after,
.btn.hover-border-2::before,
.btn.hover-border-2::after,
.btn.hover-border-2 span::before,
.btn.hover-border-2 span::after {
    position: absolute;
    content: "";
}

.btn {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.btn .text-link {
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.btn-3 {
    padding: 5px;
}

.btn-3::before,
.btn-3::after {
    background: transparent;
    z-index: 2;
}

/* 11. hover-border-1 */
.btn.hover-border-1::before,
.btn.hover-border-1::after {
    width: 10%;
    height: 25%;
    transition: 0.35s;
}
.btn.hover-border-1::before {
    top: 0;
    left: 0;
}
.btn.hover-border-1::after {
    bottom: 0;
    right: 0;
}
.btn.hover-border-1:hover::before,
.btn.hover-border-1:hover::after {
    width: 99%;
    height: 98%;
}

.btn.hover-border-2::before,
.btn.hover-border-2::after {
    width: 10%;
    height: 25%;
    transition: 0.35s;
}
.btn.hover-border-2::before {
    bottom: 0;
    left: 0;
}
.btn.hover-border-2::after {
    top: 0;
    right: 0;
}
.btn.hover-border-2:hover::before,
.btn.hover-border-2:hover::after {
    width: 99%;
    height: 99%;
}
