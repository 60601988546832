:root {
    --font-family: "Almarai";
    --side-navbar-collapsed-width: 3rem;
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/fs-albert.ttf") format("truetype");
}
@font-face {
    font-family: "FS Albert Arabic";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/fs-albert-700.ttf") format("truetype");
}
@font-face {
    font-family: "Somar";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Somar";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/ArbFONTS-Somar-Bold.otf") format("opentype");
}

@font-face {
    font-family: "DG3assomy";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/DG\ 3asomy\ Regular.ttf") format("truetype");
}
@font-face {
    font-family: "DG3assomy";
    font-weight: 300;
    font-style: thin;
    src: url("./assets/fonts/DG\ 3asomy\ Thin.ttf") format("truetype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Book.ttf") format("opentype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Medium.ttf") format("opentype");
}
@font-face {
    font-family: "ExpoArabic";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/Expo\ Arabic\ Bold.ttf") format("opentype");
}
@font-face {
    font-family: "Caveat";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/Caveat-VariableFont_wght.ttf") format("opentype");
}

@font-face {
    font-family: "DG-Forsha";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Regular.ttf") format("truetype");
}

@font-face {
    font-family: "DG-Forsha-Scribble";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/DG\ Forsha\ Scribble.ttf") format("truetype");
}

.font-expo {
    font-family: "ExpoArabic", sans-serif;
}

.font-forsha {
    font-family: "DG-Forsha", sans-serif;
}
.font-Scribble {
    font-family: "DG-Forsha-Scribble", sans-serif;
}

.font-caveat {
    font-family: "Caveat", sans-serif;
}
.font-lama {
    font-family: Lama sans, sans-serif;
}

.font-som {
    font-family: "Somar", sans-serif;
}

.font-dg {
    font-family: "DG3assomy", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Borel&family=Lalezar&family=Rakkas&display=swap");

.font-borel {
    font-family: "Borel", cursive;
}

.pisitive-nav-top {
    top: calc(var(--navbar-height));
}

.font-lem {
    font-family: "Lemonada", sans-serif;
}

@font-face {
    font-family: "FFShamel";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/FFShamelFamily-SansOneBook.ttf") format("truetype");
}
@font-face {
    font-family: "FFShamel";
    font-weight: bold;
    font-style: normal;
    src: url("./assets/fonts/FF Shamel Family Sans One Bold.ttf") format("truetype");
}

.font-ff {
    font-family: "FFShamel", sans-serif;
}

.text-stroke {
    --s: 1px;
    --sclr: #000;
    text-shadow: 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr), 0 0 var(--s) var(--sclr),
        0 0 var(--s) var(--sclr);
}
